<script>
import AddBtn from '@/components/buttons/AddBtn.vue'
import Swal from 'sweetalert2'
import { core } from "@/config/pluginInit";

export default {
  components: {
    AddBtn
  },
  data() {
    return {
      tableros: [],
      selectedTablero: null,
      personas: [],
      selectedPersona: null,
      empresas: [],
      selectedEmpresa: null,
      accion: null,
      copiar: true,
      waiting: false,
      dataTableInstance: null
    }
  },
  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn
    }
  },

  async mounted() {
    await this.getTableros()
    this.getPersonas()
    this.getEmpresas()

    core.index();
    if (window.$.fn.DataTable.isDataTable("#tableLegoAdministrador")) {
      window.$("#tableLegoAdministrador").DataTable().destroy();
    }
    this.dataTableInstance = window.$("#tableLegoAdministrador").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
  },

  methods: {
    toBuildPlantillaPersonal(tablero) {
      this.selectedTablero = tablero.id
      this.accion = 'Plantilla personal'
      this.openModal()
    },
    toBuildPlantillaEmpresa(tablero) {
      this.selectedTablero = tablero.id
      this.accion = 'Plantilla empresa'
      this.openModal()
    },
    toBuildPlantillaGlobal(tablero) {
      this.selectedTablero = tablero.id
      this.accion = 'Plantilla global'
      this.openModal()
    },
    openModal() {
      this.$bvModal.show('modalActions')
      this.selectedEmpresa = null
      this.selectedPersona = null
    },


    async getTableros() {
      const response = await this.$store.getters.fetchGet({ path: 'lego/Boards' })
      const result = await response.json()
      this.tableros = result.map(tablero => {
        let tipo
        if (tablero.isTemplate) {
          tipo = 'Plantilla'
          if (tablero.isGlobalTemplate) {
            tipo += ' global'
          } else if (tablero.empresaTemplateId) {
            tipo += ' de empresa'
          }
        } else {
          tipo = 'Tablero'
        }
        return {
          ...tablero,
          tipo
        }
      })
    },
    getPersonas() {
      this.$store.getters.fetchGet({ path: 'persona/ListPersonaAllAdmin' })
        .then(response => response.json())
        .then(result => {
          this.personas = result
        })
    },
    getEmpresas() {
      this.$store.getters.fetchGet({ path: 'Empresa/TodasLasEmpresasVanilla' })
        .then(response => response.json())
        .then(result => {
          this.empresas = result
          console.log("todas las empresas:", this.empresas);
        })
    },
    async plantillaGlobal() {
      this.waiting = true
      const response = await this.$store.getters.fetchPost({ path: `lego/CreateGlobalPlantillaFromABoard/${this.selectedTablero}/${this.userLoggedIn.id}` })
      if (response.ok) {
        this.alertPlantillaCreada()
      } else {
        this.alertPlantillaNoCreada()
      }
      this.waiting = false
      this.$bvModal.hide('modalActions')
      this.getTableros()
    },
    async plantillaEmpresa() {
      this.waiting = true
      const response = await this.$store.getters.fetchPost({ path: `lego/CreatePlantillaFromABoardToEmpresa/${this.selectedTablero}/${this.userLoggedIn.id}/${this.selectedEmpresa}` })
      if (response.ok) {
        this.alertPlantillaCreada()
      } else {
        this.alertPlantillaNoCreada()
      }
      this.waiting = false
      this.$bvModal.hide('modalActions')
      this.getTableros()

    },
    async plantillaPersona() {
      this.waiting = true
      const response = await this.$store.getters.fetchPost({ path: `lego/CloneBoardDeepById/${this.selectedTablero}/${this.selectedPersona}` })
      if (response.ok) {
        this.alertPlantillaCreada()
      } else {
        this.alertPlantillaNoCreada()
      }
      this.waiting = false
      this.$bvModal.hide('modalActions')
      this.getTableros()
    },
    alertPlantillaCreada() {
      Swal.fire({
        title: 'Plantilla creada',
        text: 'La plantilla se ha creado correctamente',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      })
      },
      alertPlantillaNoCreada() {
      Swal.fire({
        title: 'Error',
        text: 'La plantilla no se ha creado correctamente',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      })
    },
  }
}
</script>

<template>
  <b-card>
    <b-modal hide-footer hide-header id="modalActions" class="modal">
      <template #default="{ close }">
        <header class="d-flex justify-content-between mb-3 ">
          <h5>{{ accion }}</h5>
          <AddBtn class="close" @click="close" />
        </header>
        <div>
          <p>Se creara una nueva plantilla a partir del tablero seleccionado.</p>
        </div>
        <div>
          <div class="mb-3">
            <label for="">Tablero</label>
            <v-select class="lego" :reduce="option => option.id" :options="tableros" label="title"
              v-model="selectedTablero"></v-select>
          </div>
          <div v-if="accion == 'Plantilla personal'">
            
            <label for="">Persona</label>
            <v-select class="lego" :reduce="option => option.id" :options="personas" label="correo"
              v-model="selectedPersona"></v-select>
          </div>
          <div v-if="accion == 'Plantilla empresa'">
            <label for="">Empresa</label>
            <v-select class="lego" :reduce="option => option.id" :options="empresas" label="nombre"
              v-model="selectedEmpresa"></v-select>
          </div>
          <div class="mt-3">
            <vs-button :loading="waiting" class="m-0" v-if="accion == 'Plantilla global'"   success @click="plantillaGlobal">Crear
              plantilla global</vs-button>
            <vs-button :loading="waiting" class="m-0" v-if="accion == 'Plantilla empresa'"   success @click="plantillaEmpresa">Crear
              plantilla a la empresa</vs-button>
            <vs-button :loading="waiting" class="m-0" v-if="accion == 'Plantilla personal'"   success @click="plantillaPersona">Crear
              plantilla a la persona</vs-button>
          </div>
        </div>
      </template>
    </b-modal>
    <div class="custom-container">
      <header>
        <h2 class="title">
          Administrador de tableros
        </h2>
      </header>
      <div>
        <table id="tableLegoAdministrador" class="table table-bordered table-responsive-md table-striped text-center">
          <thead>
            <tr>
              <th>Nombre tablero</th>
              <th>Propietario</th>
              <th>Correo propietario</th>
              <th>Tipo de tablero</th>
              <th>Pla. Global</th>
              <th>Pla. Empresa</th>
              <th>Pla. una persona</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="tablero in tableros" :key="tablero.id">
              <td>
                {{ tablero.title }}
              </td>
              <td>
                {{ tablero.collaborators[0].name }}
              </td>
              <td>
                {{ tablero.collaborators[0].email }}
              </td>
              <td>
                {{ tablero.tipo }}
              </td>
              <td class="center-btn">
                <vs-button @click="toBuildPlantillaGlobal(tablero)">Construir</vs-button>
              </td>
              <td class="center-btn">
                <vs-button @click="toBuildPlantillaEmpresa(tablero)">Construir</vs-button>
              </td>
              <td class="center-btn">
                <vs-button @click="toBuildPlantillaPersonal(tablero)">Construir</vs-button>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </b-card>
</template>

<style scoped>
header .title {
  margin-bottom: 1rem;
}

:deep(.lego .vs__dropdown-menu),
:deep(.lego .vs__search::placeholder) {
  background-color: #0C1A30;
  border-color: rgba(255, 255, 255, 0.1);
}

:deep(.lego .vs__dropdown-toggle) {
  background: transparent;
}


.center-btn>* {
  margin: 0 auto;
}
</style>