import { render, staticRenderFns } from "./LegoViewAdministradorHelexium.vue?vue&type=template&id=9beb686e&scoped=true"
import script from "./LegoViewAdministradorHelexium.vue?vue&type=script&lang=js"
export * from "./LegoViewAdministradorHelexium.vue?vue&type=script&lang=js"
import style0 from "./LegoViewAdministradorHelexium.vue?vue&type=style&index=0&id=9beb686e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9beb686e",
  null
  
)

export default component.exports